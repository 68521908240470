import React from "react"
import clip from '../images/mp.mp4'; 


export default function About() {
  return (
    <div id="video">
          <video  id="mpvideo"
                  className="video-player"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  autoPlay
                >
                  <source
                    src={clip}
                    type="video/mp4"
                  />
                </video>

                <div class="about">
   <p id="mp">
   Mutual Players is a single-channel collage film created by juxtaposing movie clips from early English language films that feature actors of Chinese descent in Chinese roles.
    By reediting the original footage, the author aims to craft a new narrative that challenges the stereotypical ways in which Chinese-ness is portrayed in American and European cinema. 
    The author is interested in presenting a set of complicated characteristics central to the actors’ experience as Chinese or overseas Chinese and creating a safe space in which they no longer play subservient, secondary roles.
    </p>
    </div>
    </div>
  )
}